<script>
import { POST_userrecords, count } from '@/api/beijing28'


export default {
    name: 'YuLiBaoTransfer',
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        var type=this.$route.query.type==='in'
            ?'in'
            :'out'
        return {
            transferType:type==='out'
                    ?2
                    :1,
            header:{
                mainTitle:type==='out'
                    ?getWord('transfer_out3')
                    :getWord('transfer_in3'),
            },
            typeCharacter:type==='out'
                    ?getWord('out_from')
                    :getWord('in_to'),
            money:null,
            creditCycle:1000*60*60*24*2,
            creditCycleNextDay:1000*60*60*24*3,
            calculatedProfixDate:null,
        }
    },
    created() {
        
    },
    methods: {
        async creditTime(){
            var currentTimeStamp=new Date().getTime();
            var currentHour=new Date().getHours();
            if (!(this.config && this.config.start_date)) {
                var config = await this.$store.dispatch('GET_yulibaoConfig',{});
                var startHour= config.start_date.split(':')[0];
            }else {
                var startHour= this.config.start_date.split(':')[0];
            }            
            var creditCycle=this.creditCycleNextDay;
            if (currentHour<startHour) {
                creditCycle=this.creditCycle;
            }
            var calculatedTimeStamp=currentTimeStamp+creditCycle;

            var calculatedMonth=(new Date(calculatedTimeStamp).getMonth()+1)>10?(new Date(calculatedTimeStamp).getMonth()+1):'0'+(new Date(calculatedTimeStamp).getMonth()+1);
            var calculatedDate=(new Date(calculatedTimeStamp).getDate()>10?new Date(calculatedTimeStamp).getDate():'0'+new Date(calculatedTimeStamp).getDate());
            var calculatedDay='('+getWord('day_of_the_week')+this.dayFilter(new Date(calculatedTimeStamp).getDay())+')'

            return this.calculatedProfixDate=calculatedMonth+'-'+calculatedDate+calculatedDay;
        },
        transfer(){
            POST_userrecords(this.money,this.transferType).then(result=>{
                if (result.data.code==='SUCCESS') {
                    this.money=null;
                    app.Mint.Toast(getWord('transfer3')+this.typeCharacter+getWord('success'))
                }else {
                    this.money=null;
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        dayFilter(day){
            switch(day) {
                case 1:
                    return getWord('monday')
                    break;
                case 2:
                    return getWord('tuesday')
                    break;
                case 3:
                    return getWord('wednesday')
                    break;
                case 4:
                    return getWord('thursday')
                    break;
                case 5:
                    return getWord('friday')
                    break;
                case 6:
                    return getWord('saturday')
                    break;
                case 0:
                    return getWord('sunday')
                    break;
            }
        }
    },
    mounted(){
        count().then(result=>{
            if (result.data.code==='SUCCESS') {
                app.$store.commit('UPDATE_yulibaoInfo',result.data.result);
            }else {
                app.Mint.Toast(result.data.msg);
            }
        })
        this.creditTime();
    },
    computed:{
        config(){
            return this.$store.state.yulibaoConfig;
        },
        userBalance(){
            return this.$store.state.userBalance && this.$store.state.userBalance['user_money'];
        },
        yulibaoBalance(){
            return this.$store.state.yulibaoInfo && this.$store.state.yulibaoInfo['total_money'];
        },
    }
}
</script>
<template>
    <section id='ylb' :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
        >
        </Header>
        <div class="info">
            <i class="iconfont icon-recharge-active"></i>
            <h3 v-if="userBalance&&transferType">
                <b>{{ getWord('yulibao_tip1') }}{{typeCharacter}}{{ getWord('yulibao_tip2') }}</b>
                <template v-if="transferType===2">
                    {{ (yulibaoBalance||'0.00')+currencySymbol() }}
                </template>
                <template v-else>
                    {{ userBalance+currencySymbol() }}
                </template>
            </h3>
        </div>
        <div class="form">
            <h5>{{ getWord('transfer3') }}{{typeCharacter}}{{ getWord('amount_of_betting') }}（{{ currencySymbol() }}）</h5>
            <fieldset>
                <i class="iconfont icon-rmb"></i>
                <input class="money" v-model="money" :placeholder="getWord('fill_transaction',{
                    '$1':typeCharacter
                })" />
            </fieldset>
            
        </div>
        <p v-if="transferType===1">{{ getWord('estimate_date_for_income') }}<span>{{ calculatedProfixDate }}</span></p>
        <a class="submit" @click="transfer()" :class="{'active':money}">{{ getWord('confirm_transfer') }}{{typeCharacter}}</a>
    </section>
</template>

<style lang='scss' scoped>
@import "@@/assets/style/_variables";
#ylb {
    background-color: #F5F5F9;

    &.template-1,
    &.template-2 {

        .info {

            .iconfont {
                color: $TEMPLATE2-theme-color;
            }
        }

        .submit {            

            &.active {
                background-color: $TEMPLATE2-theme-color;
            }
        }
    }

    .info {
        padding: .25rem;
        display: flex;
        margin-bottom: .25rem;
        background-color:#ffffff;

        .iconfont {
            font-size: .8rem;
            color: #EC2829;
            margin-right: .25rem;
        }

        h3 {
            font-size: .28rem;
            font-weight: normal;
            color: #999999;

            b {
                font-size: .36rem;
                font-weight: normal;
                display: block;
                color: #252525;
            }
        }
    }

    .form {
        background-color:#ffffff;
        padding: .25rem;

        h5 {
            font-size: .3rem;
            color: #999999;
            margin-bottom: .25rem;
        }

        fieldset {
            border:0;

            .icon-rmb {
                font-size: .54rem;
                color: #000000;
                vertical-align: middle;
            }

            input {
                font-size: 0.54rem;
                border: 0;
                vertical-align: middle;
                width: 90%;

                &::placeholder {
                    color: #cccccc;
                    font-size: .3rem;
                }
            }
        }

        
    }

    p {
        border-top: 1px solid #CCCCCC;
        font-size: .3rem;
        padding: .25rem;
        background-color: #ffffff;

        span {
            color: #FF0F0F;
        }
    }

    .submit {        
        box-sizing: border-box;        
        font-size: .36rem;
        margin: .5rem .25rem;
        display: block;
        padding: .25rem;
        text-align: center; 
        background-color: #CCCCCC;
        color: #999999;

        &.active {
            background-color: #EC2829;
            color: #ffffff;
        }
    }
}
</style>
